(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("window"), require("fetch"), require("crypto"), require("TextEncoder"));
	else if(typeof define === 'function' && define.amd)
		define(["window", "fetch", "crypto", "TextEncoder"], factory);
	else if(typeof exports === 'object')
		exports["auth"] = factory(require("window"), require("fetch"), require("crypto"), require("TextEncoder"));
	else
		root["solid"] = root["solid"] || {}, root["solid"]["auth"] = factory(root["window"], root["fetch"], root["crypto"], root["TextEncoder"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__56__) {
return 